<template>
  <VApp>
    <div class="layout">
      <LazyDHeaderPc
        v-if="!diProvider && disabledPortal"
      />
      <LazyDHeaderMb v-else-if="!diProvider" />
      <slot />

      <LazyDFooter v-if="!diProvider" />

      <LazyDMainLoader
        v-if="showLoader"
        :time="loaderTime"
      />

      <LazyVDialog
        v-model="showDialogCity"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCityCheck
          v-if="showDialogCity"
          :redirect-to-main-page="redirectToMainPage"
          :show-dialog-address="showDialogAddress"
          @close="closeMenu('showDialogCity')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogAddress"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalAddressCheck
          :redirect="redirect"
          :title="addressCheckTitle"
          :new-window="addressCheckNewWindow"
          @close="closeMenu('showDialogAddress')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogCall"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCallMe
          v-if="showDialogCall"
          :btn-title="callMeBtn"
          @close="closeMenu('showDialogCall')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showQuizDialog"
        :fullscreen="!disabledPortal"
        :persistent="true"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalQuiz
          v-if="showQuizDialog"
          @close="showQuizDialog = false"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showMap"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalMap
          v-if="showMap"
          :redirect="redirect"
          @close="showMap = false"
        />
      </LazyVDialog>

      <ClientOnly>
        <LazyMainPageCallMeBtn v-if="!diProvider" />
        <LazyVNotification />
        <NuxtLoadingIndicator />
      </ClientOnly>
    </div>
  </VApp>
</template>

<script setup lang="ts">
import { VApp } from 'vuetify/components/VApp'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'
import { getAddresLocation } from '~/composible/getAddresLoc'
import { providersComposible } from '~/composible/getProviders'
import removeUtm from '~/composible/removeUtm'

const ctx = useNuxtApp()
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const cityStore = useCities()
const $route = useRoute()
const $router = useRouter()
const $gtm = useGtm()
const showLoader = computed(() => mainStore.getLoader)
const loaderTime = computed(() => mainStore.getLoaderTime)
const diProvider = computed(() => mainStore.getDiProvider)
const currentCity = computed(() => cityStore.getCity)
const houseUrl = computed(() => tariffsStore.getHouseUrl)
const utmArr = computed(() => mainStore.getUtmArr)
const houseType = computed(() => tariffsStore.getHouseType)
const disabledPortal = computed(() => mainStore.disabledPortal)
const cityFromCookie = useCookie('currentCity')
const addressFromCookie = useCookie('currentAddress')
const utmArrCookie = useCookie('utmArr')
const diNotProviderCookie = useCookie<any>('diNotProvider')
const addressCheckTitle = ref('')
const callMeBtn = ref('')
const addressCheckNewWindow = ref(false)
const showDialogCity = ref(false)
const showDialogAddress = ref(false)
const showDialogCall = ref(false)
const showQuizDialog = ref(false)
const redirectToMainPage = ref(false)
const showMap = ref(false)
const redirect = ref(false)

const changeMenu = () => {
  mainStore.$patch({
    disabledPortal: window.innerWidth > 1000,
    disabledMobile: window.innerWidth > 767,
  })
}

const closeMenu = (val: any) => {
  if (val === 'showDialogCity') showDialogCity.value = false
  else if (val === 'showDialogAddress') showDialogAddress.value = false
  else if (val === 'showDialogCall') showDialogCall.value = false
}

const cityLoc = async () => {
  const { getAddresLoc } = getAddresLocation()
  await getAddresLoc({})
}
const { data, error, refresh } = await useAsyncData(
  'layout' + removeUtm($route.fullPath) + (currentCity.value?.fias_id || '') + (houseUrl.value || ''),
  async () => {
    if (cityStore.softNoindex === null) {
      await cityLoc()
      return 'getCity'
    }
    else return 'noCity'
  })

const sendGtmCall = debounce((action: any, label?: any) => {
  if ($gtm) {
    $gtm.trackEvent(
      {
        event: 'trackEvent',
        category: 'call',
        action,
        label,
      })
  }
}, 300)

const sendGtmEvent = debounce(
  (event: any, category: any, action: any, label?: any) => {
    if ($gtm) {
      $gtm.trackEvent(
        {
          event,
          category,
          action,
          label,
        },
      )
    }
  },
  300,
)

const providers = ref()

const { fetchProvidersList } = providersComposible()

const sendGtm = debounce(() => {
  if ($gtm) {
    $gtm.trackEvent(
      {
        event: 'addressCheck',
        providers: providers.value.map((el: any) => {
          return {
            slug: el.provider.slug,
            id: el.provider.id,
            name: el.provider.name,
          }
        }),
        houseType: houseType.value,
        providersAlt: providers.value
          .map((el: any) => el.provider.slug)
          .join(' '),
        providersCount: providers.value.length,
      })
  }
}, 300)

const loadListen = () => {
  ctx.$listen(
    'cityDialog',
    (
      val:
        | any
        | { redirect: boolean, label: string, redirectToMainPage: boolean },
    ) => {
      sendGtmEvent('trackEvent', 'address', 'city-open', val.label)
      showDialogCity.value = val.redirect
      redirectToMainPage.value = val.redirectToMainPage || false
    },
  )
  ctx.$listen(
    'addressDialog',
    (
      val:
        | any
        | {
          redirect: boolean
          label: string
          title: string
          newWindow: boolean
          goToPage: boolean
        },
    ) => {
      if (val.goToPage && houseUrl.value) {
        $router.push({
          path: `/personal/address/${houseUrl.value}/`,
        })
        sendGtmEvent('trackEvent', 'address', 'open', val.label)
        return
      }
      redirect.value = val.redirect
      addressCheckTitle.value = val.title || ''
      addressCheckNewWindow.value = !!val.newWindow
      sendGtmEvent('trackEvent', 'address', 'open', val.label)
      showDialogAddress.value = true
    },
  )
  ctx.$listen('callDialog', (val: string) => {
    callMeBtn.value = val
    showDialogCall.value = true
  })
  ctx.$listen('quizDialog', (val: any) => {
    showQuizDialog.value = val
  })
  ctx.$listen('gtmAddressCheck', (val) => {
    sendGtm(val)
  })
  ctx.$listen('mapDialog', (val: { label: string, redirect: boolean }) => {
    sendGtmEvent('trackEvent', 'address', 'map-open', val.label)
    redirect.value = val.redirect
    showMap.value = true
  })
  ctx.$listen('gtmSendCall', (val: any) => {
    sendGtmCall(val.action, val.label)
  })
  ctx.$listen('gtmSendEvent', (val: any) => {
    sendGtmEvent(val.event, val.category, val.action, val.label)
  })
}

onBeforeMount(async () => {
  mainStore.fetchCallSlots()
  nextTick(() => {
    let recentSearch = localStorage.getItem('recentSearch')
    if (recentSearch) {
      recentSearch = JSON.parse(recentSearch)?.filter(el => el.selecetedHouse)
      tariffsStore.$patch({
        recentSearch: recentSearch,
      })
    }
  })
  if ($route.fullPath.toString().includes('utm')) {
    const queries = new URLSearchParams($route.fullPath.split('?')[1])
    const utmArr = [] as any[]
    for (const query of queries.entries()) {
      if (query[0].includes('utm')) {
        utmArr.push(query)
      }
    }
    if (utmArr.length) {
      mainStore.$patch({
        utmArr,
      })
    }
  }
})

onMounted(() => {
  loadListen()
  setTimeout(() => {
    cityStore.needGetCity = false
  }, 300)
  if (ctx?.$device?.isIos) {
    preloadComponents([
      'ModalAddressCheck',
      'ModalCityCheck',
      'ModalCallMe',
    ])
  }

  cityFromCookie.value = currentCity.value.fias_id
  utmArrCookie.value = utmArr.value
  if (!diProvider.value) diNotProviderCookie.value = true

  if (houseUrl.value) addressFromCookie.value = houseUrl.value
  else addressFromCookie.value = null

  nextTick(() => {
    if (window) {
      window.addEventListener('resize', changeMenu)
    }

    const compare = useCookie('compare')
    if (compare.value)
      tariffsStore.$patch({
        compareList: compare.value,
      })
  })
})

watch(
  () => houseUrl.value,
  async () => {
    if (houseUrl.value) {
      providers.value = await fetchProvidersList()
      sendGtm()
    }
  },
)

onBeforeUnmount(() => {
  ctx.$unlisten('addressDialog')
  ctx.$unlisten('cityDialog')
  ctx.$unlisten('callDialog')
  ctx.$unlisten('gtmAddressCheck')
  ctx.$unlisten('gtmSendCall')
  ctx.$unlisten('gtmSendEvent')
  ctx.$unlisten('addressNotif')
  ctx.$unlisten('quizDialog')
  if (window) {
    window.removeEventListener('resize', changeMenu)
  }
})

useHead(() => ({
  title: 'ДомИнтернет',
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    {
      hid: 'apple-touch-icon',
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-icon-180x180.png',
    },
    {
      rel: 'mask-icon',
      hid: 'mask-icon',
      purpose: 'maskable any',
      href: '/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
    {
      rel: () => 'canonical',
      href: () => 'https://dominternet.ru' + $route.path,
    },
  ],
  meta: [
    { name: 'apple-mobile-web-app-title', content: 'ДомИнтернет' },
    { name: 'application-name', content: 'ДомИнтернет' },
    { name: 'msapplication-TileColor', content: '#603cba' },
    { name: 'msapplication-TileImage', content: '/mstile-144x144.png' },
    { hid: 'og:type', property: 'og:type', content: 'product' },
    { hid: 'og:locale', property: 'og:locale', content: 'ru_RU' },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'dominternet.ru',
    },
    { hid: 'og:url', property: 'og:url', content: $route.path },
  ],
  script: [
    {
      hid: 'gtm',
      body: true,
      innerHTML: `(function (w, d, l) {
        w[l] = w[l] || [];
        const fias_id = '${currentCity.value?.fias_id || ''}'
        const page_version = d.cookie.includes('ab_test') ? d.cookie.split('; ').find(el => el.includes('ab_test')).replace('ab_test=', '') : ''
        w[l].push({
          originalLocation: d.location.protocol + '//' +
            d.location.hostname +
            d.location.pathname +
            d.location.search,
          fias_id,
          page_version,
        });
      })(window, document,'dataLayer')`,
      type: 'text/javascript',
    },
    {
      children: () =>
        $route.name !== 'contacts' && $route.name !== 'about'
          ? `{
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "${'https://dominternet.ru' + $route.path}",
          "logo": "https://dominternet.ru/android-icon-192x192.png"
          }`
          : undefined,
      type: () =>
        $route.name !== 'contacts' && $route.name !== 'about'
          ? 'application/ld+json'
          : undefined,
    },
  ],
}))
</script>

<style lang="scss">
.layout,
.v-overlay {
  font-family: Rubik, Roboto, Arial, sans-serif;
}
</style>
